import {
  extendVariants,
  LinkProps as NextUILinkProps,
  useLink,
} from "@nextui-org/react";
import clsx from "clsx";
import { generateTrackName } from "core/validationSchemas/utils";
import {
  cloneElement,
  ComponentProps,
  forwardRef,
  isValidElement,
  ReactNode,
} from "react";
import { useTracking } from "react-tracking";

interface BaseLinkProps
  extends Omit<NextUILinkProps, "showAnchorIcon" | "anchorIcon"> {
  /**
   * The icon to display after the text.
   */
  endIcon?: React.ReactNode;
  /**
   * ID to uniquely identify the link for tracking purposes.
   */
  id: string;
  /**
   * The icon to display before the text.
   */
  startIcon?: React.ReactNode;
}

const BaseLink = forwardRef<HTMLAnchorElement, BaseLinkProps>(
  ({ color, endIcon, id, startIcon, ...props }, ref) => {
    const getIconClone = (icon: ReactNode) =>
      isValidElement(icon)
        ? cloneElement(icon, {
            // @ts-ignore
            "aria-hidden": true,
            height: "1em",
            width: "1em",
            stroke: "currentColor",
            className: clsx({
              "mx-1 flex self-center leading-none text-current": true, // base classes
              "group-hover:text-primary-dark": color === "primary",
              "group-hover:text-secondary-dark": color === "secondary",
              "group-hover:text-success-dark": color === "success",
              "group-hover:text-warning-dark": color === "warning",
              "group-hover:text-danger-dark": color === "danger",
              "group-visited:text-accent-dark": props.isExternal,
            }),
          })
        : null;

    const startContent = getIconClone(startIcon);
    const endContent = getIconClone(endIcon);
    const { trackEvent } = useTracking();

    const { children, Component, getLinkProps } = useLink({
      ...props,
      color,
      className: clsx({
        "group !outline-none-v3 w-fit rounded font-bold data-[focus-visible]:outline-focus-visible hover:underline hover:decoration-1 hover:opacity-100":
          true, // base classes
        "visited:text-accent-dark": props.isExternal,
        [props.className ?? ""]: true, // overwrite classes
      }),
      ref,
      onPress: (e) => {
        trackEvent({
          name: generateTrackName({
            id: id,
            type: "Link",
          }),
        });

        props.onPress?.(e);
      },
    });

    return (
      <Component {...getLinkProps()}>
        <>
          {startContent}
          {children}
          {endContent}
        </>
      </Component>
    );
  },
);

BaseLink.displayName = "RecareUI.BaseLink";

const ExtendedLink = extendVariants(BaseLink, {
  variants: {
    color: {
      primary: "text-primary",
      "primary-dark": "text-primary-dark",
      secondary: "text-secondary",
      success: "text-success",
      warning: "text-warning",
      danger: "text-danger",
    },
  },
});

export type LinkProps = Omit<ComponentProps<typeof ExtendedLink>, "id"> & {
  id: string;
};

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  return <ExtendedLink {...props} ref={ref} data-testid={props.id} />;
});

Link.displayName = "RecareUI.Link";
